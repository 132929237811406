import { IAppState, IReducerState } from 'vev';
import { getScrollHeight, getViewHeight, getViewWidth } from '../utils/dom';
import { pageKeyByPath } from '../utils/route';
import { DEFAULT_APP_STATE, registerGlobalReducer } from './state';

function isAppStateAttr(attr: keyof IReducerState): attr is keyof IAppState {
  return DEFAULT_APP_STATE.hasOwnProperty(attr);
}

registerGlobalReducer((state, action, payload, pkgKey) => {
  if (action === 'set-route-path') {
    const pageKey = pageKeyByPath(payload, state.pages, state.dir);
    if (pageKey) return { ...state, route: { pageKey } };
  }

  if (action === 'update-viewport') {
    return {
      ...state,
      viewport: {
        height: getViewHeight(),
        width: getViewWidth(),
        scrollHeight: getScrollHeight()
      }
    };
  }

  // Only update state if action is attr in app state and value changed
  if (isAppStateAttr(action) && state[action] !== payload) return { ...state, [action]: payload };

  return state;
});
