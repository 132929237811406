import React, { RefObject, useEffect } from 'react';
import { useModel } from '../core/hooks';
import WidgetNode from './widget-node';

interface PageProps {
  id: string;
  className?: string;
}

function Page({ id, className }: PageProps, ref: RefObject<HTMLDivElement>) {
  const model = useModel(id);
  return (
    <div className={'__p' + (className ? ' ' + className : '')} id={id} ref={ref}>
      {model &&
        model.children &&
        model.children.map((key) => <WidgetNode key={key} id={key} section />)}
    </div>
  );
}

export default React.memo(React.forwardRef(Page));
