import { VevContent } from 'vev';
import Intersection from './intersection';
export { Intersection };
export * from './type';
export * from './dom';
export * from './animation';

export function createUID() {
  return Date.now().toString(36) + '-' + Math.round(Math.random() * 10000).toString(36);
}
