import anime from 'animejs';
import { ITransition, SimpleAnimation } from 'vev';
import { isString, isUndefined } from './type';

export function createAnimation(
  target: Element,
  tween: ITransition = 'fade',
  reverse: boolean = false,
  complete?: () => any
): anime.AnimeInstance {
  let isSimpleAnimation = false;
  if (isString(tween)) {
    tween = simpleAnime(tween);
    isSimpleAnimation = true;
  }
  if (!tween.targets) tween.targets = target;
  if (complete) tween.complete = complete;
  if (isUndefined(tween.easing)) tween.easing = 'easeOutSine';
  if (isUndefined(tween.duration)) tween.duration = 400;
  if (isUndefined(tween.opacity)) tween.opacity = [0, 1];

  if (reverse) {
    for (let key in tween) {
      if (Array.isArray(tween[key])) tween[key].reverse();
    }
  }
  return anime(tween as anime.AnimeParams);
}

export function enterAnime(
  el: Element,
  animation: SimpleAnimation,
  complete?: () => any
): anime.AnimeInstance {
  return anime({ targets: el, complete, ...simpleAnime(animation) });
}

export function leaveAnime(
  el: Element,
  animation: SimpleAnimation,
  complete?: () => any
): anime.AnimeInstance {
  return anime({ targets: el, complete, ...simpleAnime(animation, true) });
}

export function simpleAnime(
  animation: SimpleAnimation = 'fade',
  reverse?: boolean
): Partial<anime.AnimeParams> {
  const [type, dir] = animation.split('-');
  const ani: Partial<anime.AnimeParams> = {
    opacity: [0, 1],
    duration: 400,
    easing: 'easeOutSine'
  };
  if (type === 'slide') {
    let axis = /(left|right)/.test(dir) ? 'translateX' : 'translateY';
    ani[axis] = [/(down|right)/.test(dir) ? '-30%' : '30%', 0];
  } else if (type === 'scale') {
    ani.scale = [dir == 'up' ? 0.8 : 1.2, 0];
  }

  if (reverse) {
    for (let key in ani) {
      if (Array.isArray(ani[key])) ani[key].reverse();
    }
  }
  return ani;
}

let singleFrameCbs: ((timestamp?: number) => void)[] = [];
const foreverFrameCbs: ((timestamp?: number) => void)[] = [];
let pendingFrameId: false | number = false;

function onFrame(timestamp: number) {
  for (let cb of singleFrameCbs) cb(timestamp);
  for (let cb of foreverFrameCbs) cb(timestamp);
  if (singleFrameCbs.length) singleFrameCbs = [];
  // If any forever frames then request new frame
  if (foreverFrameCbs.length) doRaf();
  else pendingFrameId = false;
}

function doRaf() {
  pendingFrameId = requestAnimationFrame(onFrame);
}

/**
 * Request animation Frame
 * @returns cancel/unsubscribe function
 */
export function raf(cb: (timestamp?: number) => void, runForever: boolean = false): () => void {
  const list = runForever ? foreverFrameCbs : singleFrameCbs;
  if (list.indexOf(cb) === -1) list.push(cb);

  if (!pendingFrameId) doRaf();

  return () => {
    const index = list.indexOf(cb);
    if (index !== -1) list.splice(index, 1);
  };
}
