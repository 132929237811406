import React, { forwardRef, LegacyRef, useState, useEffect, useRef } from 'react';
import { hasResolved, preloadPageHTML } from '../../core/page-resolver';
import { useGlobalStore } from '../../core';
import { IPage, IAppState, IWidget } from 'vev';
import { pagePathByKey } from '../../utils/route';

interface ILink {
  mode: 0 | 1 | 2 | 3 | 4;
}

interface IPageLink extends ILink {
  page: string;
  tweenIn?: any;
  tweenOut?: any;
  inFront?: any;
}

interface IWidgetLink extends IPageLink {
  widget: { key: string; page: string };
}

interface IPhoneLink extends ILink {
  phone: string;
}

interface IEmailLink extends ILink {
  email: string;
  subject?: string;
}

interface IExternalLink extends ILink {
  href: string;
  target: boolean;
  nofollow: boolean;
}

interface LinkProps {
  to: ILink;
  className?: string;
  style?: { [attr: string]: any };
  children: React.ReactNode;
}

export function getLinkUrl(link: ILink, state: IAppState): string {
  if (!link) return 'https://www.vev.design';

  switch (link.mode) {
    case 0:
      const pageKey = (link as IPageLink).page;
      return pagePathByKey(pageKey, state.pages, state.dir);
    case 1:
      if ((link as IWidgetLink).widget) {
        const { page, key } = (link as IWidgetLink).widget;
        return `${pagePathByKey(page, state.pages, state.dir)}#${key}`;
      }
      return '';
    case 3:
      const { email, subject } = (link || {}) as IEmailLink;
      return 'mailto:' + email + (subject ? `?subject=${subject}` : '');
    case 4:
      return 'tel:' + (link as IPhoneLink).phone;
    default:
      return (link as IExternalLink).href;
  }
}

export function getLinkTween(link: ILink): string | false {
  if ((link.mode <= 1 && (link as IPageLink).tweenIn) || (link as IPageLink).tweenOut) {
    return JSON.stringify({
      inFront: (link as IPageLink).inFront,
      tweenIn: (link as IPageLink).tweenIn,
      tweenOut: (link as IPageLink).tweenOut
    });
  }

  return false;
}

function Link({ to, children, ...rest }: LinkProps, ref: LegacyRef<HTMLAnchorElement>) {
  const href = useGlobalStore((state) => to && getLinkUrl(to, state), [to]);
  const r = useRef<HTMLAnchorElement>();
  // let href: undefined | string,
  const tween: string | false = to ? getLinkTween(to) : false;

  useEffect(() => {
    if (to) r.current.href = href;
  });

  return (
    <a
      key={href}
      ref={r}
      href={href}
      data-tween={tween}
      rel={to && (to as IExternalLink).nofollow ? 'nofollow' : undefined}
      target={to && (to as IExternalLink).target ? '_blank' : undefined}
      {...rest}
    >
      {children}
    </a>
  );
}
export default React.memo(forwardRef(Link));
